<script setup lang="ts">
import AppMenuItem from "@/components/app-menu/AppMenuItem.vue";
import { useAppStore } from "@/stores/appStore";
import { useAuthStore } from "@/stores/authStore";
import { useUserStore } from "@/stores/userStore";

const authStore = useAuthStore();
const appStore = useAppStore();
const userStore = useUserStore();
</script>

<template>
  <div
    class="menu__fixed w-screen bg-white text-primary shadow-app-menu lg:w-auto lg:bg-inherit lg:text-inherit lg:shadow-none"
    v-if="authStore.isLoggedIn"
  >
    <div
      class="flex items-start max-lg:justify-evenly lg:justify-between gap-2 overflow-y-auto px-4 pb-2 pt-1 lg:gap-[20px]"
    >
      <AppMenuItem
        label="Buyers Guide"
        v-if="appStore.plot"
        :to="{
          name: 'buyers-guide',
          params: { plotId: appStore.plot.id ?? 0 },
        }"
        iconName="icon-guide"
      />
      <AppMenuItem
        label="Forms"
        v-if="appStore.plot"
        :to="{
          name: 'forms',
          params: { plotId: appStore.plot.id ?? 0 },
        }"
        iconName="icon-form"
      />
      <AppMenuItem
        v-if="appStore.plot"
        label="Property"
        :to="{
          name: 'property',
        }"
        iconName="icon-home"
      />
      <AppMenuItem
        v-if="appStore.plot"
        label="Notifications"
        :to="{
          name: 'notifications',
          params: { plotId: appStore.plot.id ?? 0 },
        }"
        iconName="icon-bell"
      />
      <AppMenuItem
        label="Select Development"
        v-if="userStore.hasMultiplePlots"
        :to="{
          name: 'development-select',
        }"
        iconName="icon-settings"
      />
      <AppMenuItem
        class="opacity-75 max-lg:hidden"
        label="Logout"
        :to="{ name: 'logout' }"
        iconName="icon-lock"
      />
    </div>
  </div>
</template>
