export enum OrderTypes {
  None = "",
  StudioUpgrade = "studio_upgrades",
  PlotReservation = "plot_reservation",
}

export enum OrderStatus {
  Pending = "pending",
  Completed = "completed",
  Canceled = "canceled",
}
