import { defineStore } from "pinia";
import { useDevelopmentPlotApi } from "@/helpers/useDevelopmentPlotApi";
import { DevelopmentDataType } from "@/types/DevelopmentTypes";
import { PlotDataType } from "@/types/PlotTypes";
import {
  ApiFetchDevelopmentsResponse,
  ApiFetchPlotsResponse,
} from "@/types/ApiTypes";
import { ToastServiceMethods } from "primevue/toastservice";

const { getDevelopmentsRequest, getPlotsRequest } = useDevelopmentPlotApi();

export let useAppStore = defineStore("app", {
  state: (): AppState => ({
    isLoading: false,
    isDarkMode: window.matchMedia("(prefers-color-scheme: dark)").matches,
    isDialogOpen: false,
    showBackLink: false,
    scrolled: false,
    scroll: {
      y: 0,
      bottomArrived: false,
    },

    development: null,
    developments: null,
    plot: null,
    plots: null,

    title: {
      main: "",
    },

    toast: null,
  }),

  persist: {
    omit: ["isLoading", "isDialogOpen"],
  },

  actions: {
    setTitle(title: string) {
      this.title.main = title;
    },

    clearTitle() {
      this.title.main = "";
    },

    setLoading(loading: boolean) {
      this.isLoading = loading;
    },

    setScrolled(scrolled: boolean) {
      this.scrolled = scrolled;
    },

    initializeDarkModeListener() {
      const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

      // Set up a listener for changes to the color scheme
      const updateDarkMode = (event?: MediaQueryListEvent) => {
        this.isDarkMode = event?.matches ?? mediaQuery.matches;
      };

      // Initial check
      updateDarkMode();

      // Add listener
      mediaQuery.addEventListener("change", updateDarkMode);

      // Return cleanup function to remove the listener
      return () => mediaQuery.removeEventListener("change", updateDarkMode);
    },

    toggleDarkMode() {
      // Manually toggle dark mode
      this.isDarkMode = !this.isDarkMode;
    },

    setPlot(plot: PlotDataType | null) {
      this.plot = plot;
    },

    setDevelopment(development: DevelopmentDataType | null) {
      this.development = development;
    },

    async getDevelopments() {
      this.setLoading(true);

      try {
        const developments = await getDevelopmentsRequest();

        this.developments = developments.data;
      } catch (error: any) {
        console.error("Error getting developments: ", error);
      } finally {
        this.setLoading(false);
      }
    },

    async getPlots(developmentId: string) {
      this.setLoading(true);

      try {
        const plots = await getPlotsRequest(developmentId);

        this.plots = plots.data;
      } catch (error: any) {
        console.error("Error getting plots: ", error);
      } finally {
        this.setLoading(false);
      }
    },
  },

  getters: {
    getScrollPositionPercent: (state) => {
      // Pixel amount to scroll before the final state of 1 is reached
      const PIXEL_AMOUNT = 100;
      let percentage = 0;

      if (state.scroll.y > PIXEL_AMOUNT) return 1;
      if (state.scroll.bottomArrived) return 1;

      percentage = state.scroll.y / PIXEL_AMOUNT;

      return percentage.toFixed(2);
    },

    isInert: (state) => {
      return state.isDialogOpen;
    },

    developmentsData: (state) => (search?: string) => {
      if (search) {
        /**
         * This search will currently search all string values in the development object
         * which means it will also search fields not displayed on the page such as the
         * development address etc.
         */
        const excludedKeys = new Set(["created_at", "updated_at"]);

        const searchInObject = (obj: any): boolean => {
          return Object.entries(obj).some(([key, value]) => {
            if (excludedKeys.has(key)) {
              return false; // Skip searching in excluded keys
            }

            if (typeof value === "string") {
              return value.toLowerCase().includes(search.toLowerCase());
            } else if (typeof value === "object" && value !== null) {
              return searchInObject(value);
            }

            return false;
          });
        };

        return state.developments?.data.filter((development) =>
          searchInObject(development),
        );
      }

      return state.developments?.data;
    },

    plotsData: (state) => (search?: string) => {
      if (search) {
        /**
         * This search will currently search all string values in the plot object
         * which means it will also search fields not displayed on the page such as the
         * user email address etc.
         */
        const excludedKeys = new Set(["created_at", "updated_at"]);

        const searchInObject = (obj: any): boolean => {
          return Object.entries(obj).some(([key, value]) => {
            if (excludedKeys.has(key)) {
              return false; // Skip excluded keys
            }

            if (typeof value === "string") {
              return value.toLowerCase().includes(search.toLowerCase());
            } else if (typeof value === "object" && value !== null) {
              return searchInObject(value);
            }

            return false;
          });
        };

        return state.plots?.data.filter((plot) => searchInObject(plot));
      }

      return state.plots?.data;
    },
  },
});

export interface AppState {
  showBackLink: boolean;
  isLoading: boolean;
  isDarkMode: boolean;
  isDialogOpen: boolean;

  development: null | DevelopmentDataType;
  developments: null | ApiFetchDevelopmentsResponse;
  plot: null | PlotDataType;
  plots: null | ApiFetchPlotsResponse;

  title: {
    main: string;
  };

  scrolled: boolean;
  scroll: {
    y: number;
    bottomArrived?: boolean;
  };

  toast: null | ToastServiceMethods;
}
