import axios from "@/helpers/useApi";
import { apiUrl } from "@/helpers/apiConfig"; // Import the apiUrl constant

export function useBuildStagesApi() {
  const getBuildStagesRequest = async (plotId: number) => {
    try {
      return await axios.get(`${apiUrl}/api/plots/${plotId}/build-stages`);
    } catch (error: any) {
      console.error("Error getting build stages:", error);

      throw error.response.data.message;
    }
  };

  return {
    getBuildStagesRequest,
  };
}
