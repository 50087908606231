import { defineStore } from "pinia";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useAppStore } from "./appStore";
import { useBuildStagesApi } from "@/helpers/useBuildStagesApi";
import { usePropertyApi } from "@/helpers/usePropertyApi";
import { ApiFetchBuildStagesResponse } from "@/types/ApiTypes";
import { BuildStageType } from "@/types/BuildStageTypes";
import { PropertyType } from "@/types/PropertyTypes";

const { getBuildStagesRequest } = useBuildStagesApi();
const { getPlotRequest } = usePropertyApi();

dayjs.extend(utc);

export let usePropertyStore = defineStore("property", {
  state: (): PropertyStoreState => ({
    property: {
      customers: [],
    },
    buildStages: {
      data: [],
    },
  }),

  persist: true,

  actions: {
    async fetchBuildStages(plotId: number) {
      const appStore = useAppStore();

      appStore.setLoading(true);

      const request = getBuildStagesRequest(plotId);

      request
        .then((response) => {
          if (response.status === 200) {
            this.buildStages = response.data;
          } else {
            console.error(response);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          appStore.setLoading(false);
        });
    },

    async fetchProperty(plotId: number, developmentId: number) {
      const appStore = useAppStore();

      appStore.setLoading(true);

      const request = getPlotRequest(plotId, developmentId);

      request
        .then((response) => {
          if (response.status === 200) {
            this.property = response.data.data;
          } else {
            console.error(response);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          appStore.setLoading(false);
        });
    },

    getDate(date: string) {
      return dayjs.utc(date).format("D MMMM YYYY");
    },

    getCompletedLabel(stage: BuildStageType) {
      return stage.finished_at
        ? `Completed on ${this.getDate(stage.finished_at)}`
        : "Not complete";
    },
  },

  getters: {
    hasCustomers: (state) => {
      return state.property.customers.length > 0;
    },
  },
});

interface PropertyStoreState {
  property: PropertyType;
  buildStages: ApiFetchBuildStagesResponse;
}
