import axios from "@/helpers/useApi";
import { apiUrl } from "@/helpers/apiConfig"; // Import the apiUrl constant

export function usePropertyApi() {
  const getPlotRequest = async (plotId: number, developmentId: number) => {
    try {
      return await axios.get(
        `${apiUrl}/api/developments/${developmentId}/plots/${plotId}`,
      );
    } catch (error: any) {
      console.error("Error getting property information:", error);

      throw error.response.data.message;
    }
  };

  return {
    getPlotRequest,
  };
}
