import { defineStore, Store } from "pinia";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useToast } from "primevue/usetoast";
import { TOAST_TIME } from "@/helpers/appConfig";

import { useStudioSelectionApi } from "@/helpers/useStudioSelectionApi";
import { useAppStore } from "@/stores/appStore";
import { StoreStudioSelectionOptionType } from "@/types/StudioSelectionTypes";
import {
  ApiCreateOrderRequestBody,
  ApiFetchStudioSelectionCategoryResponse,
  ApiFetchStudioSelectionItemsResponse,
} from "@/types/ApiTypes";
import { useOrderStore } from "./orderStore";
import { OrderTypes } from "@/enums/Orders";
import { nextTick } from "vue";

dayjs.extend(utc);

const { getCategoriesRequest, getItemsRequest } = useStudioSelectionApi();

export let useStudioSelectionStore = defineStore("studioSelection", {
  state: (): state => ({
    activeCategory: 0,
    categories: {
      data: [],
      links: {
        first: null,
        last: null,
        prev: null,
        next: null,
      },
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        links: [],
        path: "",
        per_page: 1,
        to: 1,
        total: 1,
      },
    },
    items: [],
  }),

  persist: true,

  actions: {
    async fetchCategories(plotId: string, params: object = {}) {
      try {
        const response = await getCategoriesRequest(plotId, params);

        if (response.status === 200) {
          this.categories = response.data;
        } else {
          console.error(response);
        }
      } catch (error) {
        console.error(error);
      }
    },

    async fetchItems(id: string, plotId: string) {
      this.clearItems();

      try {
        const response = await getItemsRequest(id, plotId);

        if (response.status === 200) {
          this.addOrUpdateCategoryItems(parseInt(id), response.data);
        } else {
          console.error("Error getting items: ", response);
        }
      } catch (error) {
        console.error("Error getting items: ", error);
      }
    },

    async fetchAll(plotId: string, params: object = {}) {
      const appStore = useAppStore();
      appStore.setLoading(true);

      this.$reset();
      useOrderStore().$reset();

      try {
        // Fetch categories if they are not already loaded
        if (!this.categories.data.length) {
          await this.fetchCategories(plotId, params);
        }

        // Fetch items for each category
        if (this.categories.data.length && !this.items.length) {
          await Promise.all(
            this.categories.data.map((category) =>
              this.fetchItems(category.id.toString(), plotId),
            ),
          );
        }

        // Pull in a remote order and sync selections
        await this.getRemoteOrder(plotId);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        appStore.setLoading(false);
      }
    },

    async getRemoteOrder(plotId: string) {
      const orderStore = useOrderStore();

      try {
        await orderStore.searchOrders(OrderTypes.StudioUpgrade, {
          plot_id: parseInt(plotId),
          with_products: true,
          active: true,
        });

        this.setOptionSelections();
      } catch (error) {
        console.error("Error getting remote order: ", error);
      }
    },

    setOptionSelections() {
      // Handle preselected options
      this.items.forEach((category) => {
        category.items.data.forEach((item) => {
          const activeItemId = item.active_studio_upgrade_item_option_id;

          if (activeItemId) {
            const option = item.options.find(
              (option) => option.id === activeItemId,
            ) as StoreStudioSelectionOptionType;

            if (option) {
              option.isPreSelected = true;
              option.isSelected = true;
            }
          }
        });
      });

      this.mergeSelectionsFromOrder();
    },

    mergeSelectionsFromOrder() {
      const orderStore = useOrderStore();

      // Set the user selected options based on order products
      if (orderStore.order.order_products.length) {
        orderStore.order.order_products.forEach((product) => {
          const option = this.findOptionByProductId(product.product_id);

          if (option && option.active) {
            this.handleOptionSelection(
              option as StoreStudioSelectionOptionType,
            );
          }
        });
      }
    },

    async reload(plotId: string, params: object = {}) {
      const orderStore = useOrderStore();
      orderStore.$reset();
      this.$reset();

      await this.fetchAll(plotId, params);
    },

    async handleOptionsUnmount(orderRequest?: ApiCreateOrderRequestBody) {
      const orderStore = useOrderStore();
      const appStore = useAppStore();

      try {
        if (!orderRequest) return;

        if (
          !orderStore.hasOrder ||
          (orderStore.hasOrder && orderStore.isOrderCanceled)
        ) {
          orderStore.findOrCreateOrder(orderRequest);
        }

        if (
          orderStore.hasOrder &&
          orderStore.isOrderPending &&
          orderStore.order.type === OrderTypes.StudioUpgrade
        ) {
          if (orderRequest.products.length > 0) {
            // await orderStore.getOrder(orderStore.order.id);
            await orderStore.findOrCreateOrder(orderRequest);
            // this.setOptionSelections();

            const localProducts = orderRequest.products;
            const remoteOrderProducts = orderStore.order.order_products;

            const localProductsToAdd = localProducts.filter((product) => {
              return !remoteOrderProducts.some(
                (existingProduct) =>
                  existingProduct.product_id === product.product_id,
              );
            });

            const productsToAdd = [...localProductsToAdd];

            if (!orderStore.hasPaymentIntent && productsToAdd.length > 0) {
              // Payment intent doesn't exist so the order can be updated
              await orderStore.patchOrder(orderStore.order.id, {
                products: productsToAdd,
              });

              // Once products have been patched we need to then unselect them to prevent the preselected options from being sent as a patch every time - this way they will only be patched once explicitely clicked on
              localProductsToAdd.forEach((product) => {
                const option = this.findOptionByProductId(product.product_id);

                if (option) {
                  this.unselectPreSelectedOption(
                    option as StoreStudioSelectionOptionType,
                  );
                }
              });

              appStore.toast?.add({
                severity: "success",
                summary: "Selections added",
                detail: "Your selections have been added to the order.",
                life: TOAST_TIME("success"),
              });
            } else if (
              productsToAdd.length > 0 &&
              orderStore.hasPaymentIntent
            ) {
              this.showPaymentRequiredToast();
            }
          }
        }
      } catch {
        appStore.toast?.add({
          severity: "error",
          summary: "Selections not added",
          detail: "Your selections could not be added to the order.",
          life: TOAST_TIME("error"),
        });
      }
    },

    findOptionByProductId(productId: number) {
      return this.items
        .flatMap((category) => category.items.data)
        .flatMap((item) => item.options)
        .find((option) => option.house_type.product.id === productId);
    },

    clearItems() {
      this.items = [];
    },

    addOrUpdateCategoryItems(
      categoryId: number,
      items: ApiFetchStudioSelectionItemsResponse,
    ) {
      if (categoryId === 0) return;

      const existingItems = this.items.find(
        (item) => item.categoryId === categoryId,
      );

      if (!existingItems) {
        this.items.push({
          categoryId: categoryId,
          items: items,
        });
      }
    },

    getCategoryById(id: number) {
      return this.categories.data.find((category) => category.id === id);
    },

    setCategory(categoryId: number) {
      this.activeCategory = categoryId ?? 0;
    },

    selectOption(option: StoreStudioSelectionOptionType) {
      for (const category of this.items) {
        if (!category.items?.data) continue;

        for (const item of category.items.data) {
          if (!item.options) continue;

          // Check if the option belongs to this item
          const itemOption = item.options.find(
            (itemOption) => itemOption.id === option.id,
          ) as StoreStudioSelectionOptionType;

          if (itemOption) {
            // Select the clicked option and deselect others
            item.options.forEach((opt) => {
              !(opt as StoreStudioSelectionOptionType).isSelected
                ? ((opt as StoreStudioSelectionOptionType).isSelected =
                    opt.id === option.id)
                : ((opt as StoreStudioSelectionOptionType).isSelected = false);
            });

            return; // Exit once the option is handled
          }
        }
      }
    },

    unselectPreSelectedOption(option: StoreStudioSelectionOptionType) {
      for (const category of this.items) {
        if (!category.items?.data) continue;

        for (const item of category.items.data) {
          if (!item.options) continue;

          // Check if the option belongs to this item
          const itemOption = item.options.find(
            (itemOption) => itemOption.id === option.id,
          ) as StoreStudioSelectionOptionType;

          if (itemOption && itemOption.isPreSelected) {
            itemOption.isSelected = false;
            return; // Exit once the option is handled
          }
        }
      }
    },

    handleOptionSelection(option: StoreStudioSelectionOptionType) {
      this.selectOption(option);
    },

    getBasketTotal() {
      return this.basketItems.reduce((acc, category) => {
        return (
          acc +
          category.items.reduce((acc, item) => {
            return (
              acc +
              item.options.reduce((acc, option) => {
                return acc + option.house_type.product.price;
              }, 0)
            );
          }, 0)
        );
      }, 0);
    },

    getBasketProducts() {
      return this.allBasketItemsIncludingDefault.flatMap((category) =>
        category.items.flatMap((item) =>
          item.options.map((option) => option.house_type.product),
        ),
      );
    },

    getBasketProductById(id: number) {
      return this.getBasketProducts().find((product) => product.id === id);
    },

    getProductById(id: number) {
      return this.items
        .flatMap(
          (category) =>
            category.items?.data?.flatMap(
              (item) =>
                item.options?.map((option) => option.house_type.product) ?? [],
            ) ?? [],
        )
        .find((product) => product.id === id);
    },

    showPaymentRequiredToast() {
      const orderStore = useOrderStore();
      const appStore = useAppStore();

      appStore.toast?.removeAllGroups();

      appStore.toast?.add({
        severity: "warn",
        summary: "Payment required",
        life: TOAST_TIME("error"),
        detail: `
              <div>
                <button role="button" id="btnUnlockSelections" class="flex items-center justify-between gap-2">
                  <span>Your selections are currently confirmed. Click here to edit your selections.</span>
                  <span class="w-[12px]">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                      <path fill="currentColor" fill-rule="evenodd" d="M8 0 6.6 1.4 12.2 7H0v2h12.2l-5.6 5.6L8 16l8-8z" />
                    </svg>
                  </span>
                </button>
              </div>
            `,
      });

      nextTick(() => {
        const button = document.querySelector(`#btnUnlockSelections`);
        if (button) {
          button.addEventListener("click", () => {
            orderStore.removePaymentIntent();
          });
        }
      });
    },
  },

  getters: {
    getActiveCategory: (state) => {
      return state.categories.data.find(
        (category) => category.id === state.activeCategory,
      );
    },

    isCategoryEditable: (state) => (categoryId: string) => {
      return (
        state.categories.data.find(
          (category) => category.id === parseInt(categoryId) && category.active,
        ) ?? false
      );
    },

    getItemsByCategoryId: (state) => (categoryId: string) => {
      return state.items.find(
        (item) => item.categoryId === parseInt(categoryId),
      );
    },

    basketItems: (state) => {
      if (!state.items || state.items.length === 0) return [];

      return state.items
        .map((category) => ({
          ...category,
          items: category.items?.data
            ?.map((item) => ({
              ...item,
              options: (
                item.options as StoreStudioSelectionOptionType[]
              ).filter((option) => option.isSelected && !option.isPreSelected),
            }))
            .filter((item) => item.options.length > 0), // Only keep items with selected options
        }))
        .filter((category) => category.items?.length > 0); // Only keep categories with items
    },

    allBasketItemsIncludingDefault: (state) => {
      if (!state.items || state.items.length === 0) return [];

      return state.items
        .map((category) => ({
          ...category,
          items: category.items?.data
            ?.map((item) => ({
              ...item,
              options: (
                item.options as StoreStudioSelectionOptionType[]
              ).filter((option) => option.isSelected),
            }))
            .filter((item) => item.options.length > 0), // Only keep items with selected options
        }))
        .filter((category) => category.items?.length > 0); // Only keep categories with items
    },

    // Getter to return the count of all selected options
    basketCount(): number {
      return this.basketItems.reduce((categoryAcc, category) => {
        // Sum up the count of selected options in each category
        return (
          categoryAcc +
          category.items.reduce((itemAcc, item) => {
            // Add the count of selected options for each item
            return itemAcc + item.options.length;
          }, 0)
        );
      }, 0);
    },

    isPreSelected: (state) => (optionId: number) => {
      return state.items.some((category) =>
        category.items.data.some(
          (item) => item.active_studio_upgrade_item_option_id === optionId,
        ),
      );
    },

    isUserSelected: (state) => (productId: number) => {
      return state.items.some((category) =>
        category.items.data.some((item) =>
          item.options.some((option) => {
            if ("isSelected" in option) {
              return option.id === productId && option.isSelected;
            }

            return false;
          }),
        ),
      );
    },
  },
});

interface state {
  categories: ApiFetchStudioSelectionCategoryResponse;
  activeCategory: number;
  items: CategoryItemType[];
}

interface CategoryItemType {
  categoryId: number;
  items: ApiFetchStudioSelectionItemsResponse;
}
