<script setup lang="ts">
const props = defineProps({
  label: {
    type: String,
    required: true,
  },

  to: {
    type: [String, Object],
    required: true,
  },

  iconName: {
    type: String,
    required: true,
  },

  class: {
    type: String,
    default: "fill-current",
    required: false,
  },
});
</script>

<template>
  <RouterLink
    :to="props.to"
    class="link flex max-md:min-w-[75px] flex-col items-center justify-center gap-1 lg:w-auto"
    :class="props.class"
  >
    <InlineSvg :name="props.iconName" class="w-[24px] lg:hidden" />

    <span>{{ props.label }}</span>
  </RouterLink>
</template>

<style>
.link {
  @apply text-center text-caption font-caption leading-caption text-grey lg:text-base lg:font-normal lg:text-inherit;
}

.link.router-link-active {
  @apply font-bold text-primary lg:font-normal lg:text-secondary;
}
</style>
