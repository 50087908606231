import { defineStore } from "pinia";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useAppStore } from "./appStore";
import { useOrdersApi } from "@/helpers/useOrdersApi";
import { v4 as uuid } from "uuid";

import {
  OrderProductType,
  OrderType,
  PatchedProductCondensedType,
  ProductCondensedType,
  ProductType,
} from "@/types/StripeTypes";
import { OrderStatus, OrderTypes } from "@/enums/Orders";
import {
  ApiCreateOrderRequestBody,
  ApiPatchOrderRequestBody,
  ApiSearchOrdersParams,
  ApiSearchOrdersResponse,
} from "@/types/ApiTypes";
import { useStripeApi } from "@/helpers/useStripeApi";
import { useStudioSelectionStore } from "./studioSelectionStore";

const {
  getOrderRequest,
  createOrderRequest,
  searchOrdersRequest,
  patchOrderRequest,
  deleteOrderRequest,
} = useOrdersApi();

dayjs.extend(utc);

export let useOrderStore = defineStore("order", {
  state: (): OrderState => ({
    order: {
      id: 0,
      uuid: "",
      type: OrderTypes.None,
      version: "",
      total_price: 0,
      status: OrderStatus.Pending,
      expires_at: null,
      order_products: [
        {
          id: 0,
          product_id: 0,
          quantity: 0,
          price_paid: 0,
          cost_price: 0,
          actual_price: 0,
        },
      ],
      stripe_payment_intent: null,
    },
    allOrders: {
      data: [],
      links: {
        first: null,
        last: null,
        prev: null,
        next: null,
      },
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        links: [],
        path: "",
        per_page: 1,
        to: 1,
        total: 1,
      },
    },
    stripeKey: "",
    isCreatingOrder: false,
  }),

  persist: true,

  actions: {
    async createOrder(order: ApiCreateOrderRequestBody) {
      const appStore = useAppStore();
      try {
        appStore.setLoading(true);
        const response = await createOrderRequest(order);
        if (response.status === 201) {
          this.order = response.data.data; // Ensure reactive assignment
          return this.order; // Return the created order
        } else {
          console.error(response);
          throw new Error("Failed to create order");
        }
      } catch (error) {
        console.error("Error in createOrder:", error);
        throw error; // Re-throw for the caller to handle
      } finally {
        appStore.setLoading(false);
      }
    },

    async patchOrder(orderId: number, body: ApiPatchOrderRequestBody) {
      try {
        const response = await patchOrderRequest(orderId, body);

        if (response.status === 200) {
          this.order = response.data.data;
          return this.order;
        } else {
          console.error(response);
          throw new Error("Failed to update order");
        }
      } catch (error) {
        console.error("Error in patchOrder:", error);
        throw error; // Re-throw for the caller to handle
      }
    },

    async getOrder(orderId: number) {
      const appStore = useAppStore();

      appStore.setLoading(true);

      try {
        if (orderId === 0) {
          throw new Error("Invalid order ID");
        }

        const response = await getOrderRequest(orderId);

        if (response.status === 200) {
          this.order = response.data.data;
        } else {
          console.error(response);
        }
      } catch (error) {
        console.error(error);
      } finally {
        appStore.setLoading(false);
      }
    },

    async searchOrders(orderType: OrderTypes, params: ApiSearchOrdersParams) {
      try {
        const response = await searchOrdersRequest(orderType, params);
        if (response.status === 200) {
          const data: ApiSearchOrdersResponse = response.data;
          this.allOrders = data;
          if (data.data.length > 0) {
            // Set the last order in the list as the current order
            this.order = data.data[data.data.length - 1];
          } else {
            console.warn("No orders found in search result.");
          }
        }
      } catch (error) {
        console.error("Error in searchOrders:", error);
      }
    },

    async createPaymentIntent(order: OrderType) {
      const appStore = useAppStore();
      const stripeApi = useStripeApi();

      appStore.setLoading(true);

      const request = stripeApi.createPaymentIntentRequest(order);

      request
        .then((response) => {
          if (response.status === 200) {
            if (response.data.data.status === OrderStatus.Completed) {
              this.orderCompleted();
            }

            this.order = response.data.data;
          } else {
            console.error(response);

            this.getOrder(order.id);
          }
        })
        .catch((error) => {
          console.error(error);
          this.getOrder(order.id);
        })
        .finally(() => {
          appStore.setLoading(false);
        });
    },

    async verifyPaymentIntent(order: OrderType) {
      const appStore = useAppStore();
      const stripeApi = useStripeApi();

      try {
        appStore.setLoading(true);

        const response = await stripeApi.verifyPaymentIntentRequest(order);

        if (response.status === 200) {
          if (response.data.data.status === OrderStatus.Completed) {
            this.orderCompleted();
          }

          this.order = response.data.data;
        } else {
          console.error(response);
          await this.getOrder(order.id);
        }
      } catch (error) {
        console.error(error);
        await this.getOrder(order.id);
      } finally {
        appStore.setLoading(false);
      }
    },

    async removePaymentIntent() {
      const appStore = useAppStore();
      const order = this.order;
      // This is a pretend method to remove the payment intent
      // What is actually needed is to:
      // 1. Get the products from the current order
      const productsToCopy = order.order_products.map((product) => {
        return {
          product_id: product.product_id,
          price: product.price_paid,
        } as ProductCondensedType;
      });

      // 2. Cancel the current order
      await deleteOrderRequest(order.id);

      // 3. Create a new order with the same products
      await this.createOrder({
        type: order.type,
        uuid: uuid(),
        products: productsToCopy,
        metadata: {
          plot_id: appStore.plot?.id || 0,
        },
      });
    },

    async refreshStripeKey() {
      const stripeApi = useStripeApi();

      try {
        const request = await stripeApi.getStripeKeyRequest();

        if (request.status === 200) {
          this.stripeKey = request.data.publishable_key;

          return this.stripeKey;
        } else {
          console.error(request);
          throw new Error("Failed to get Stripe key");
        }
      } catch (error) {
        console.error(error);
        throw new Error("Failed to get Stripe key");
      }
    },

    async findOrCreateOrder(orderRequest?: ApiCreateOrderRequestBody) {
      try {
        if (orderRequest) {
          // Search for an existing order
          await this.searchOrders(orderRequest.type, {
            plot_id: orderRequest.metadata.plot_id,
            with_products: true,
            active: true,
          });

          if (
            !this.hasOrder ||
            !this.isOrderPending ||
            this.order.type !== orderRequest.type
          ) {
            if (orderRequest.products.length > 0) {
              await this.createOrder(orderRequest);
            }

            await this.searchOrders(orderRequest.type, {
              plot_id: orderRequest.metadata.plot_id,
              with_products: true,
              active: true,
            });
          }

          if (this.isOrderCanceled) {
            await this.removePaymentIntent();
          }
        }
      } catch (error) {
        console.error("Error in findOrCreateOrder:", error);
        throw error;
      }
    },

    async setupCheckout(orderRequest?: ApiCreateOrderRequestBody) {
      const appStore = useAppStore();

      try {
        appStore.setLoading(true);

        if (this.isOrderComplete || this.isOrderProcessing) {
          console.log("Order is complete or processing. Skipping setup.");
          return;
        }

        // Refresh the Stripe key
        await this.refreshStripeKey();

        // Find or create the order if it doesn't exist
        if (orderRequest) {
          await this.findOrCreateOrder(orderRequest);
        }

        // Fetch the updated order if it exists
        if (this.hasOrder) {
          await this.getOrder(this.order.id);
        }

        // Ensure a payment intent is available
        if (this.order.id && !this.hasPaymentIntent) {
          await this.createPaymentIntent(this.order);
        }

        // Final fetch to ensure the order is up-to-date
        if (this.order.id) {
          await this.getOrder(this.order.id);
        } else {
          console.error("Order setup failed.");
        }
      } catch (error) {
        console.error("Error in setupCheckout:", error);
        throw error;
      } finally {
        appStore.setLoading(false);
      }
    },

    makeOrderProducts(products: ProductType[]) {
      return products.map((product) => {
        return {
          product_id: product.id,
          price: product.price,
        } as ProductCondensedType;
      });
    },

    getDate(date: string) {
      return dayjs.utc(date).format("D MMMM");
    },

    orderCompleted() {
      console.log("Order completed");
    },

    getProductName(product: OrderProductType) {
      if (this.order.type === OrderTypes.PlotReservation) {
        const appStore = useAppStore();
        if (appStore.development && appStore.plot) {
          return `Reservation for Plot ${appStore.plot.name} at ${appStore.development.name}`;
        }

        return `Plot Reservation`;
      }

      if (this.order.type === OrderTypes.StudioUpgrade) {
        const studioSelectionStore = useStudioSelectionStore();
        const selectionProduct = studioSelectionStore.getProductById(
          product.product_id,
        );

        return selectionProduct?.name || `Studio Upgrade`;
      }

      return `No name: ${product.product_id}`;
    },
  },

  getters: {
    hasOrder(state): boolean {
      return state.allOrders.data.length > 0 && state.order.id > 0;
    },

    hasPaymentIntent(state): boolean {
      if (!this.hasOrder) return false;
      if (!state.order.stripe_payment_intent) return false;

      return (
        state.order.stripe_payment_intent !== null ||
        state.order.stripe_payment_intent !== undefined
      );
    },

    isPaymentRequired(state): boolean {
      if (!this.hasOrder) return false;

      return (
        [OrderStatus.Pending, OrderStatus.Canceled].includes(
          state.order.status,
        ) &&
        (!state.order.stripe_payment_intent ||
          (state.order.stripe_payment_intent &&
            state.order.stripe_payment_intent.status ===
              "requires_payment_method"))
      );
    },

    isOrderPending(state): boolean {
      if (!this.hasOrder) return false;

      return state.order.status === OrderStatus.Pending;
    },

    isOrderProcessing(state): boolean {
      if (!this.hasOrder) return false;

      return (
        !this.isPaymentRequired &&
        state.order.status === OrderStatus.Pending &&
        ["processing", "succeeded"].includes(
          state.order.stripe_payment_intent?.status || "",
        )
      );
    },

    isOrderComplete(state): boolean {
      if (!this.hasOrder) return false;

      return (
        !this.isPaymentRequired && state.order.status === OrderStatus.Completed
      );
    },

    isOrderCanceled(state): boolean {
      if (!this.hasOrder) return false;

      return state.order.status === OrderStatus.Canceled;
    },
  },
});

interface OrderState {
  order: OrderType;
  allOrders: ApiSearchOrdersResponse;
  stripeKey: string;
  isCreatingOrder: boolean;
}
