<script setup lang="ts">
import { ref, getCurrentInstance, Component } from "vue";
import HeightTransition from "@/components/shared/HeightTransition.vue";

const emits = defineEmits(["open", "close", "toggle"]);

const props = defineProps({
  open: {
    type: Boolean,
    required: false,
    default: false,
  },

  id: {
    type: Number,
    required: false,
    default: 0,
  },

  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const instance = getCurrentInstance() ?? { uid: props.id };
const id = ref(instance.uid);
let isOpen = ref(props.open);
let isDisabled = ref(props.disabled);

const toggle = () => {
  if (isOpen.value) {
    close();
  } else {
    open();
  }
};

const open = () => {
  isOpen.value = true;
  emits("open", id.value);
};

const close = () => {
  isOpen.value = false;
  emits("close", id.value);
};

defineExpose({
  open,
  close,
  toggle,
  id,
});

export interface AccordionItemType {
  id?: number;
  title?: string;
  content?: string;
  component?: Component;
  components?: AccordionItemType[];
  props?: any;
  open?: boolean;
  disabled?: boolean;
}
</script>

<template>
  <div
    class="accordion-item"
    :disabled="isDisabled"
    :class="{ 'is-open': isOpen, 'is-disabled': isDisabled }"
  >
    <div class="accordion__title">
      <button
        type="button"
        @click="toggle"
        :aria-expanded="isOpen"
        :disabled="isDisabled"
        :aria-controls="'content-accordion-' + id"
        :id="'btn-accordion-' + id"
        class="text-body-text button w-full border-b border-black bg-secondary px-[10px] py-[15px] text-left text-primary dark:border-white dark:text-primary"
      >
        <slot name="title"></slot>
      </button>
    </div>

    <HeightTransition>
      <div
        v-show="isOpen"
        class="accordion__content !max-h-[unset]"
        :id="'content-accordion-' + id"
        :aria-labelledby="'btn-accordion-' + id"
      >
        <div class="accordion__inner">
          <div class="card__content">
            <slot></slot>
          </div>
        </div>
      </div>
    </HeightTransition>
  </div>
</template>

<style>
:root {
  --accordion-icon-size: 36px;
  --accordion-transition: all 0.15s ease-in-out;
}
</style>

<style scoped>
.accordion__content {
  max-height: 0;
  transition: all 0.15s ease-in-out;
  transition: var(--accordion-transition, all 0.15s ease-in-out);

  &.is-open {
    overflow: visible;
  }
}
</style>
