export enum FormStatus {
  Available = "available",
  ReadOnly = "read_only",
  Completed = "completed",
  Locked = "locked",
  Expired = "expired",
}

export enum FormUUID {
  // Reservation
  ReservationFormChecklist = "d1bef081-aad5-4945-b705-761c4e3dc689",
  ReservationAgreementForm = "1a9cea75-06b3-42c4-95e8-338422465c6f",
  ReservationPaymentForm = "8c40bf5f-6739-450b-8b6b-21e6d6dc3d12",

  // Studio Selection
  StudioSelectionPlaceholder = "15589d74-abe8-4550-b7c7-14dfe293a2d5",

  // Purchaser
  HomeDemoForm = "8d6a509d-b6d8-4c71-baf8-6c8567170b10",
  HomeQualityInspectionForm = "8953554f-f6dd-4677-86ed-fc884395f32c",
  CustomerHandoverForm = "352116bd-b95e-40de-84c9-48411cfcb924",
  CustomerWelcomePackForm = "2069fa5c-15a2-4be5-b9cc-d2fa61ee65cb",
  CourtesyVisitForm = "52e7daa3-4bc2-48c6-8d0a-294826e3f9e2",
  TenDayPurchaserReportForm = "62aecfd4-2ee0-4254-86b7-4f036e479a35",
  TenDayPurchaseSatisfactionForm = "40f05c6e-214c-4d4a-ba2b-33724ba9f168",
  FiveToSevenMonthPurchaserSatisfactionForm = "9064f15e-2e67-499e-b72d-c5d552a98355",

  // Null to keep TS happy
  Null = "",
}
