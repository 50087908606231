import axios from "@/helpers/useApi";
import { apiUrl } from "@/helpers/apiConfig"; // Import the apiUrl constant
import {
  ApiCreateOrderRequestBody,
  ApiPatchOrderRequestBody,
  ApiSearchOrdersParams,
  ApiSearchOrdersResponse,
} from "@/types/ApiTypes";
import { OrderTypes } from "@/enums/Orders";
import { ProductCondensedType } from "@/types/StripeTypes";

export function useOrdersApi() {
  const getOrderRequest = async (orderId: number) => {
    try {
      return await axios.get(`${apiUrl}/api/orders/${orderId}`);
    } catch (error: any) {
      console.error("Error getting order:", error);

      throw error.response.data.message;
    }
  };

  const createOrderRequest = async (newOrder: ApiCreateOrderRequestBody) => {
    try {
      return await axios.post(`${apiUrl}/api/orders`, newOrder);
    } catch (error: any) {
      console.error("Error creating order:", error);

      throw error.response.data.message;
    }
  };

  const searchOrdersRequest = async (
    orderType: OrderTypes,
    params: ApiSearchOrdersParams,
  ) => {
    try {
      return await axios.get(`${apiUrl}/api/orders/${orderType}`, {
        params,
      });
    } catch (error: any) {
      console.error("Error searching orders:", error);

      throw error.response.data.message;
    }
  };

  const patchOrderRequest = async (
    orderId: number,
    body: ApiPatchOrderRequestBody,
  ) => {
    try {
      return await axios.patch(`${apiUrl}/api/orders/${orderId}`, {
        ...body,
      });
    } catch (error: any) {
      console.error("Error patching order:", error);

      throw error.response.data.message;
    }
  };

  const deleteOrderRequest = async (orderId: number) => {
    try {
      return await axios.delete(`${apiUrl}/api/orders/${orderId}`);
    } catch (error: any) {
      console.error("Error deleting order:", error);

      throw error.response.data.message;
    }
  };

  return {
    createOrderRequest,
    getOrderRequest,
    searchOrdersRequest,
    patchOrderRequest,
    deleteOrderRequest,
  };
}
